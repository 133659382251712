export type Stipulation = {
  data: FormState;
  _id: string;
  createdAt: string | Date;
  updatedAt: string | Date;
};
export type FormState = {
  dealId: string;
  info: Info;
};
export enum StipulationStatus {
  PENDING = 'pending',
  APPROVED = 'approved',
  REJECTED = 'rejected',
}
export type Info = {
  id: string;
  description: string;
  url?: string | null;
  rejectedUrls?: {
    url: string;
    uploadedAt?: string;
    uploadedBy?: 'applicant' | 'coApplicant' | 'dealer' | 'manager';
  }[];
  status: StipulationStatus;
  notificationSendDate: string;
  viewed: string;
  notifiedEmails: {
    emails: string[];
    type: 'applicant' | 'coApplicant' | 'dealer';
  }[];
  uploadedBy?: 'applicant' | 'coApplicant' | 'dealer' | 'manager';
  uploadedAt?: string;
  canBeUploadedBy: ('applicant' | 'coApplicant' | 'dealer')[];
  user: {
    firstName: string | null;
    lastName: string | null;
    email: string | null;
  };
};
export type UpdateStipulationRequest = Stipulation & {
  metadata: object;
  fileName: string;
};
