import { useMutation, useQuery } from '@tanstack/react-query';
import { useState } from 'react';

import { queryClient } from '../app/App';
import {
  getStipulations,
  uploadFileAndUpdateStipulation,
} from '../http/stipulations';
import { MutationError } from '../types';
import { HttpQuery } from '../types/http';
import { Stipulation } from '../types/stipulations';
import { toast } from 'react-toastify';

export const useUploadFileAndUpdateStipulation = (
  successCallback: () => void
) => {
  const [progress, setProgress] = useState(0);
  const mutation = useMutation<any, MutationError, any>(
    uploadFileAndUpdateStipulation(setProgress),

    {
      onSuccess: (mutatedStipulation) => {
        toast.success('File uploaded successfully', { position: 'top-center' });
        successCallback();
        if (mutatedStipulation) {
          queryClient.setQueriesData<{
            stipulations: Stipulation[];
            names: string;
          }>(['stipulations'], (oldData) => {
            const newStipulations = (oldData?.stipulations ?? [])?.map(
              (stipulation) => {
                if (stipulation._id === mutatedStipulation._id) {
                  return {
                    ...mutatedStipulation,
                  };
                }
                return stipulation;
              }
            );
            return {
              names: oldData?.names ?? '',
              stipulations: newStipulations,
            };
          });
        }
      },
    }
  );
  return {
    uploadFileAndUpdateStipulation: mutation.mutateAsync,
    loading: mutation.isLoading,
    success: mutation.isSuccess,
    error: mutation.error,
    progress,
  };
};

export const useGetStipulations = (query?: HttpQuery, enabled = false) => {
  const queryKey = ['stipulations'];

  const { data, isLoading, error } = useQuery<
    { stipulations: Stipulation[]; names: string },
    MutationError,
    { stipulations: Stipulation[]; names: string }
  >({
    queryKey: queryKey,
    queryFn: getStipulations(query),
    enabled,
  });

  return {
    data,
    isLoading,
    error,
  };
};
