export const convertBlobToBase64 = (blob: Blob) => {
  return getBase64(blob).then((result) => result);
};

export const getBase64 = (file: File | Blob) => {
  return new Promise((resolve) => {
    let baseURL: string | ArrayBuffer | null = '';
    // Make new FileReader
    const reader = new FileReader();
    // Convert the file to base64 text
    reader.readAsDataURL(file);

    // on reader load somthing...
    reader.onload = () => {
      // Make a fileInfo Object
      baseURL = reader.result;
      resolve(baseURL);
    };
  });
};

export const formatDate = (
  date: string | undefined | Date,
  dateStyle: 'full' | 'long' | 'medium' | 'short' = 'medium',
  withTime: Boolean = false
) => {
  if (date === undefined || date === null) {
    return '';
  } else {
    const parsedDate = new Date(date);

    return !isNaN(parsedDate.getTime())
      ? new Intl.DateTimeFormat('en-US', {
          // @ts-ignore
          dateStyle,
          // @ts-ignore
          timeStyle: withTime ? 'short' : undefined,
        }).format(parsedDate)
      : '';
  }
};
